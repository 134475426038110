import { Box } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

import Header from "./Header";

import { Theme, useTheme } from "@mui/material/styles";
import APIFetchContainer, {
  VisualComponentProps,
} from "../../containers/APIFetchContainer";
import { API_NAMES } from "../../Enums";
import Footer from "../../components/Footer";
import { ReactNode, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { useMainContext } from "../../contexts/MainContext";
// import mobileBg from "static/images/bg_comparison_mobile.png";
// import desktopBg from "static/images/bg_comparison.png";

const VisualComponent: React.FunctionComponent<VisualComponentProps> = ({
  resultData,
}) => {
  if (resultData) {
    window.app_preload = resultData;
    return <Content data={resultData} />;
  } else {
    return <div></div>;
  }
};
export type ContentProps = {
  data: any;
};
const Content: React.FunctionComponent<ContentProps> = ({ data }) => {
  const { mainContextData } = useMainContext();
  const { pathname } = useLocation();
  const [showFooter, setShowFooter] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log({ pathname });
    if (pathname.includes("events/")) {
      setShowFooter(false);
    } else {
      setShowFooter(true);
    }
  }, [pathname]);

  const theme: Theme = useTheme() as Theme;
  return (
    pathname !== "/" ? (
      <Box
        sx={{
          minHeight: "100%",
          backgroundImage: {
            xs: 'url("/static/images/bg_comparison_mobile.png")',
            sm: 'url("/static/images/bg_comparison.png")',
          },
          backgroundSize: "cover",
          backgroundRepeat: "repeat",
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            minHeight: "400px",
            paddingTop: theme.header.height,
            position: "relative",
            margin: "auto",
          }}
        >
          <Outlet />
        </Box>
        {showFooter && <Footer />}
      </Box>
    ) : (
      <Box 
        sx={{ position: "relative", minHeight: "100vh" }} 
        className="bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: "url('/static/images/backgrounds/homepage_8bit.png')" }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            minHeight: "400px",
            paddingTop: theme.header.height,
            position: "relative",
            margin: "auto",
          }}
        >
          <Outlet />
        </Box>
        {showFooter && <Footer />}
      </Box>
    )
  );
};

const MainLayout = () => {
  if (window.app_preload != null) {
    return <Content data={window.app_preload} />;
  }
  return (
    <APIFetchContainer
      apiName={API_NAMES.APP_PRELOAD}
      needDataFetch={true}
      VisualComponent={VisualComponent}
    />
  );
};
export default MainLayout;
