import { FC, ReactNode } from "react";

import { Grid, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import EventComparisonHeader from "../../../components/EventComparisonHeader";

interface AboutUsPageProp {
  children?: ReactNode;
}

const AboutUsInfo = [
  {
    id: "about-us-1",
    title: "Compare \n Ticket Prices",
    description: `Did you know that ticket prices can vary across different sites for the
same events? At Tixplorer, we tackle this problem head-on. We search
10,000+ tickets for each event to find you the best deals.`,
    imageUrl: "static/images/about/about-info1.svg",
    buttonText: "Compare Tickets",
  },
  {
    id: "about-us-2",
    title: "Save \n Money",
    description: `
      There's nothing worse than finding the perfect ticket, only to be hit with a
bunch of hidden fees at checkout. Our price comparison model takes all
those extra charges into account, so you can see immediately how much
you're saving.
      `,
    imageUrl: "static/images/about/about-info2.svg",
    buttonText: "Register Now",
  },
  {
    id: "about-us-3",
    title: "Discover \n Events",
    description: `
      Whether you're into concerts, sports, theater, or comedy, we've got
something for you. Currently, we offer access to over 150,000 events
across the US and Canada.
      `,
    imageUrl: "static/images/about/about-info3.svg",
    buttonText: "Discover Artists",
  },
  {
    id: "about-us-4",
    title: "All Events \n Tracked",
    description: `
        At Tixplorer, we're always monitoring events to deliver the best customer
experience and maximize your savings. Our mission is to help you spend
less time searching for tickets and more time enjoying the events!
      `,
    imageUrl: "static/images/about/about-info4.svg",
    buttonText: "Search Events",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function AboutUsPage(props: AboutUsPageProp) {
  // const theme = useTheme() as Theme;
  // const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center">
      {/* <BgHeader title='ABOUT US' /> */}
      <div className="flex flex-col items-center justify-center pt-12 md:pt-20 lg:pt-28">
        <EventComparisonHeader />
      </div>
      <div className="my-16 mb-24 space-y-16 flex flex-col items-center justify-center px-4 lg:max-w-4xl">
        {AboutUsInfo.map((info, infoIdx) => (
          <div
            key={info.id}
            className={classNames(
              "flex items-center md:items-start justify-center flex-col",
              infoIdx % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"
            )}
          >
            <img
              alt=""
              src={`/${info.imageUrl}`}
              className="object-cover object-center h-60 w-auto mb-8 md:mb-0"
            />
            <div
              className={classNames(
                infoIdx % 2 === 0 ? "md:mr-6" : "md:ml-12",
                "text-center md:text-start"
              )}
            >
              <h3 className="text-2xl md:text-3xl font-medium text-gray-900 sm:whitespace-pre-line lg:whitespace-nowrap">
                {info.title}
              </h3>
              <p className="mt-2 md:text-lg text-slate-900">
                {info.description}
              </p>
              {/* <Link
                to="/register"
                className=" bg-primary-btn !text-white px-4 py-2 rounded-3xl mt-3 inline-block "
              >
                {info.buttonText}
              </Link> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default AboutUsPage;

const steps = [
  "Sign up in the top right corner",
  "Buy a ticket through Tixplorer",
  "Locate and submit your order number",
  "Receive TixPoints within 48 hours",
  "Choose withdrawal method",
];

export function HowItWorks() {
  return (
    <Grid
      container
      columns={steps.length}
      style={{
        background: "#F1F1F2",
        borderRadius: 10,
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
      }}
    >
      {steps.map((step, index) => (
        <HowItWorksEntry
          key={"hiwe-" + index}
          step={index + 1}
          description={step}
        />
      ))}
    </Grid>
  );
}

type HowItWorksEntryProps = {
  step: number;
  description: string;
};
function HowItWorksEntry(props: HowItWorksEntryProps) {
  return (
    <Grid item style={{ padding: "20px 20px" }} md={1} xs={steps.length}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Typography variant="h2" textAlign="center">
            {props.step}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" textAlign="center">
            {props.description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
