export interface ExternalEventProps {
  eventName: string;
  event: any;
  score: number;
  onClick?: () => void;
}

export type EventTicketComparison = {
  section: string;
  row: string;
  quantity: string;
  stubhub: Ticket;
  gametime: Ticket;
  tickpick: Ticket;
  vividSeats: Ticket;
};
export type ComparisonData = Record<string, EventTicketComparison>;

export type Ticket = {
  section: string;
  row: string;
  url: string;
  price: number;
  priceWithFees: number;
  minQuantity: number;
  maxQuantity: number;
};

export type TicketMap = Record<string, Ticket>;

export type MarkupFee = {
  from: number;
  to?: number;
  percentage: number;
};

export const QTY_FILTER_VALUES = Array.from({ length: 10 }, (_, i) => i + 1);
