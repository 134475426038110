import { ComparisonData, QTY_FILTER_VALUES } from "../types/ticketTypes";

const filterComparisonBySection = (
  comparisonData: ComparisonData,
  selectedSections: string[]
): ComparisonData => {
  const newComparisonData: ComparisonData = {};

  Object.keys(comparisonData).forEach((key) => {
    if (selectedSections.includes(comparisonData[key].section)) {
      newComparisonData[key] = comparisonData[key];
    }
  });

  return newComparisonData;
};

// Providers Keys
export type TicketSource = 'stubhub' | 'tickpick' | 'vividSeats' | 'gametime';

export const filterTickets = (
  tickets: ComparisonData,
  qty?: number,
  sections?: string[]
): ComparisonData => {
  const sectionFiltered =
    sections && sections.length > 0
      ? filterComparisonBySection(tickets, sections)
      : tickets;

  const filteredTickets: ComparisonData = {};

  Object.keys(sectionFiltered).forEach((key) => {
    const ticket = sectionFiltered[key];

    if (!qty) {
      filteredTickets[key] = ticket;
      return;
    }

    if (ticket.quantity.includes('-')) {
      const [minQty, maxQty] = ticket.quantity.split('-').map(Number);
      if (qty >= minQty && qty <= maxQty) {
        filteredTickets[key] = ticket;
      }
    } else {
      const intQty = parseInt(ticket.quantity, 10);
      if (qty === QTY_FILTER_VALUES[QTY_FILTER_VALUES.length - 1]) {
        if (intQty >= qty) {
          filteredTickets[key] = ticket;
        }
      } else if (intQty === qty) {
        filteredTickets[key] = ticket;
      }
    }
  });

  // Proceed to sorting the filtered tickets
  return sortTickets(filteredTickets);
};

// Helper function to sort tickets based on the lowest priceWithFees and stubhub presence
function sortTickets(tickets: ComparisonData): ComparisonData {
  const sources: TicketSource[] = ['stubhub', 'tickpick', 'vividSeats', 'gametime'];

  const entries = Object.entries(tickets).map(([key, ticket]) => {
    // Calculate the lowest priceWithFees among the available providers
    let lowestPrice = Infinity;
    sources.forEach((source) => {
      const sourceTicket = ticket[source];
      if (sourceTicket && sourceTicket.priceWithFees != null) {
        const price =
          typeof sourceTicket.priceWithFees === 'number'
            ? sourceTicket.priceWithFees
            : parseFloat(sourceTicket.priceWithFees);
        if (price < lowestPrice) {
          lowestPrice = price;
        }
      }
    });

    const hasStubhub = !!ticket['stubhub'];

    return {
      key,
      ticket,
      lowestPrice,
      hasStubhub,
    };
  });

  // Sort the entries based on the presence of stubhub and lowest priceWithFees
  entries.sort((a, b) => {
    if (a.hasStubhub && !b.hasStubhub) {
      return -1;
    } else if (!a.hasStubhub && b.hasStubhub) {
      return 1;
    } else {
      // Both have or don't have stubhub; sort by lowest priceWithFees
      return a.lowestPrice - b.lowestPrice;
    }
  });

  // Reconstruct the sorted ComparisonData object
  const sortedComparisonData: ComparisonData = {};

  entries.forEach(({ key, ticket }) => {
    sortedComparisonData[key] = ticket;
  });

  return sortedComparisonData;
}

