import SuspenseLoader from "../../../components/SuspenseLoader";
import { useParams } from "react-router-dom";
import APIFetchContainer, {
  VisualComponentProps,
} from "../../../containers/APIFetchContainer";
import { API_NAMES } from "../../../Enums";
import Event from "./event";
import { useContext, useEffect, useRef } from "react";
import BgHeader from "../../../layouts/BgHeader";
import { Theme } from "@mui/material";
import PageContentWrapper from "../../../layouts/PageContentWrapper";
import { useTheme } from "@mui/material/styles";
import QueryString from "query-string";
import { useMainContext } from "../../../contexts/MainContext";

const VisualComponent: React.FunctionComponent<VisualComponentProps> = ({
  resultData,
}) => {
  const theme = useTheme() as Theme;
  if (resultData) {
    //console.log("Event result data", resultData);
    let img = resultData?.image?.large;
    if (resultData.performer?.type === "golf") {
      img = "/static/images/backgrounds/golf.jpeg";
    } else if (resultData.performer?.type === "nascar") {
      img = "/static/images/backgrounds/nascar.jpeg";
    }

    return (
      <>
        {/* <BgHeader image={img} /> */}
        <PageContentWrapper>
          <Event eventObject={resultData} />
        </PageContentWrapper>
      </>
    );
  } else {
    return (
      <>
       
      </>
    );
    /*
     <div className="flex flex-col items-center mt-[10vh]">
        <h2 className="text-lg text-center max-w-2xl mb-16">
          Just{" "} 
          <span className="relative inline-block">
          seconds
            <div className="bg-[#DC19FF] opacity-20 absolute top-2 w-full h-4 shadow-sm"></div>
          </span>{" "}
          away from your dreams tickets...
          <br/>
          Thank you for your patience!
        </h2>
      </div>
      <SuspenseLoader embedded={true} height="100px" />
    */
  }
};
const Events = () => {
  const { eventId, performerId } = useParams();
  /* const { performerId } = QueryString.parse(
    window.location.hash
  ); */
  const ref = useRef();
  useEffect(() => {
    if (ref.current === undefined) return;
    const s = ref.current as any;
    s.onRequestDataChange({ eventId: eventId, performerId: performerId });
  }, [eventId, performerId]);
  return (
    <APIFetchContainer
      ref={ref}
      apiName={API_NAMES.EVENT}
      needDataFetch={true}
      VisualComponent={VisualComponent}
      requestData={{ eventId: eventId, performerId: performerId }}
    />
  );
};
export default Events;
