import {
  Grid,
  Container,
  Typography,
  CircularProgress,
  InputLabel,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { formatToLocal } from "../../../utils/DateUtils";
import PageTitle from "../../../components/page/PageTitle";
import { useMemo, useState } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Box from "@mui/system/Box";
import PriceComparisonItem from "../../../components/PriceComparisonItem";
import Stack from "@mui/system/Stack";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Select, { MultiValue, SingleValue } from "react-select";
import SuspenseLoader from "../../../components/SuspenseLoader";

import "./event.css";
import {
  ComparisonData,
  QTY_FILTER_VALUES,
  Ticket,
  TicketMap,
  MarkupFee,
} from "../../../types/ticketTypes";
import { filterTickets } from "../../../utils/ticketUtils";

function calculateMarkupFee(
  ticketPrice: number,
  markupFees: MarkupFee[]
): number {
  let markupPercentage = 0;

  for (const fee of markupFees) {
    if (ticketPrice >= fee.from && ticketPrice <= (fee.to ?? Infinity)) {
      markupPercentage = fee.percentage;
      break;
    }
  }

  const markupFee = (ticketPrice * markupPercentage) / 100;

  return markupFee;
}

// function localizePrice(amount: number, currency: string): string {
//   return amount.toLocaleString("en-US", {
//     style: "currency",
//     currency: currency,
//   });
// }

// const ExternalEvent = ({
//   eventName,
//   event,
//   score,
//   onClick,
// }: ExternalEventProps) => {
//   const theme = useTheme() as Theme;

//   const imageUrl =
//     event.eventProvider.imageURL ||
//     window.app_preload?.eventProviders.find(
//       (p: Record<string, unknown>) =>
//         p.name === event.eventProvider.name.toLowerCase()
//     )?.imageURL;

//   return (
//     <>
//       <Divider style={{ background: theme.colors.alpha.black[10] }} />
//       <Grid
//         container
//         spacing={2}
//         sx={{
//           width: "100%",
//           paddingTop: theme.spacing(2),
//           paddingBottom: theme.spacing(2),
//         }}
//         justifyItems={"center"}
//       >
//         <Grid
//           item
//           xs={12}
//           md={3}
//           display="flex"
//           alignItems="center"
//           justifyContent={"center"}
//         >
//           <img src={imageUrl} height={60} />
//         </Grid>
//         <Hidden mdDown>
//           <Grid
//             item
//             xs={0}
//             md={2}
//             display="flex"
//             justifyContent={"center"}
//             alignItems={"center"}
//           >
//             {/* {
//                         Array(score).fill(0).map((_, index: number) => <StarRounded key={index} fontSize='small' htmlColor={theme.colors.primary.main} />)
//                     }
//                     {
//                         Array(5 - score).fill(0).map((_, index: number) => <StarRounded key={index} fontSize='small' htmlColor={theme.colors.alpha.black[10]} />)
//                     } */}
//           </Grid>
//         </Hidden>
//         <Grid
//           item
//           xs={12}
//           md={2}
//           display="flex"
//           justifyContent={"center"}
//           alignItems={"center"}
//         >
//           {event.soon || !event.minPrice /* || !event.priceCurrency */ ? (
//             "check site for prices"
//           ) : (
//             <span style={{ textAlign: "center" }}>
//               <span>FROM </span>
//               <Typography variant="h4" component={"span"} fontWeight={600}>
//                 {/* {(event.minPrice - (event.eventProvider.name.toLowerCase() === "stubhub"? 1 : 0)).toLocaleString("en-US", { */}
//                 {/* {(event.minPrice + calculateMarkupFee(event.minPrice, event.eventProvider.markupFees || [])).toLocaleString("en-US", { */}
//                 {event.minPriceWithMarkup.toLocaleString("en-US", {
//                   style: "currency",
//                   currency: event.priceCurrency ?? "USD",
//                 })}
//               </Typography>
//               <Typography variant="caption" component={"div"}>
//                 ALL-IN
//               </Typography>
//               {/* <Typography variant="caption" component={"div"}>
//                 {event.eventProvider.markupFees
//                   ? `Fees Not Included`
//                   : "Fees Included"}
//               </Typography> */}
//             </span>
//           )}
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           md={2}
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//         >
//           {/* (() => {
//               const cashbackPercent = window.app_preload.eventProviders.find((p: Record<string, unknown>) => p.name === event.eventProvider.name.toLowerCase())?.cashbackPercent;
//               return (
//                 cashbackPercent && event.minPrice?
//                 <Grid container justifyContent='center' alignItems='end' style={{ height: 'inherit', }}>
//                   <Typography variant="caption" color="#e6ae08" align="center">
//                     EARN
//                   </Typography>
//                   <Typography variant="h4" style={{ marginLeft: 5, marginRight: 5 }} align="center">
//                     {// {localizePrice(event.minPrice * (cashbackPercent / 100), event.priceCurrency)} {event.maxPrice? `- ${localizePrice(event.maxPrice * (cashbackPercent / 100), event.priceCurrency)}` : null}
//                     }
//                     {Math.floor(event.minPrice * (cashbackPercent))}+
//                   </Typography>
//                   <Typography variant="caption" color="#e6ae08" align="center">
//                     TixPoints
//                   </Typography>
//                 </Grid>
//                 :
//                 null
//               )
//             })() */}
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           md={3}
//           display="flex"
//           justifyContent={"center"}
//           alignItems="center"
//         >
//           {/* <a href={event.url === undefined || event.url === '' ? event.eventProvider.url : event.url} target="_blank" rel='noreferrer'> */}
//           <ActionButton
//             text={
//               event.url === undefined || event.url === ""
//                 ? "Find More"
//                 : "Buy Tickets"
//             }
//             sx={{ width: "120px" }}
//             onClick={() => {
//               ReactGA.event({
//                 category: "engagement",
//                 action: "event-ticket-click",
//                 label: eventName,
//               });
//               ReactGA.event({
//                 category: "engagement",
//                 action: "ticket-provider-click",
//                 label: event.eventProvider.name,
//               });
//               if (onClick) {
//                 onClick();
//               } else {
//                 window
//                   .open(event.url ?? event.eventProvider.url, "_blank")
//                   ?.focus();
//               }
//             }}
//           />
//         </Grid>
//       </Grid>
//     </>
//   );
// };

const fetchStubhubTickets = async (eventId: string) => {
  const res = await axios.get(
    `${process.env.REACT_APP_STUBHUB_SCRAPER_URL}/${eventId}`
  );

  //console.log("fetchStubhubTickets()", res.data.result)
  return res.data.result;
};

const fetchTickpickTickets = async (eventUrl?: string | null) => {
  const eventId = getEventIdFromUrl("tickpick", eventUrl);
  if (!eventId) return null;

  const res = await axios.get(
    `${process.env.REACT_APP_TICKPICK_SCRAPER_URL}?eventId=${eventId}`
  );
  //console.log("fetchTickpickTickets()", res.data.result)
  return res.data.result;
};

// GameTime

const fetchGameTimeTicketsV2 = async (eventId?: string | null) => {
  try {
    const response = await axios.get(`https://gametime.co/events/${eventId}`);

    const parser = new DOMParser();
    const doc = parser.parseFromString(response.data, 'text/html');

    const scriptEl = doc.querySelector('div#content + script');

    if (scriptEl) {
      const scriptContent = scriptEl.textContent;

      // Create a new function to evaluate the script content safely
      const func = new Function('window', scriptContent + '; return window.__data;');

      // Execute the function with an empty object as 'window' to avoid polluting the global scope
      const data = func({});

      // Access the ticket listings 
      const listings = data['redux']['data']['listings']['allListings'];

      // Return the transformed listings
      return Object.entries(listings).map(([id, details]: [string,any]) => {
        const quantities = Array.isArray(details.quantity) && details.quantity.length > 0
          ? details.quantity.map(Number).filter((q: number) => !isNaN(q))
          : [];
      
        let minQuantity = null;
        let maxQuantity = null;
      
        if (quantities.length > 0) {
          minQuantity = Math.min(...quantities);
          maxQuantity = Math.max(...quantities);
      
          // If there's only one value, assign it to both minQuantity and maxQuantity
          if (quantities.length === 1) {
            minQuantity = maxQuantity = quantities[0];
          }
        }
      
        return {
          section: details?.section.toString(),
          row: details?.row.toString(),
          url: `https://gametime.co/events/${details?.event_id}/listings/${details?.ticket_id}?zoom=10`,
          price: (details?.price?.prefee ?? 0) / 100,
          priceWithFees: (details?.price?.total ?? 0) / 100,
          minQuantity: minQuantity,
          maxQuantity: maxQuantity
        };
      });
    } else {
      console.log('GameTime Script element not found');
      return [];
    }
  } catch (e) {
    console.error('GameTime Error fetching event tickets:', e);
    return [];
  }
};

const fetchGameTimeTickets = async (eventId?: string | null) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/scrape/gametime/events/${eventId}/tickets`);
    // The response data is already parsed as JSON
    //console.log('Gametime Fetch Function:', `${process.env.REACT_APP_BASE_URL}/scrape/gametime/events/${eventId}/tickets`)
    //console.log('Gametime Fetch Function Data:', response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching tickets:', error);
    return [];
  }
};


const getEventIdFromUrl = (provider: string, eventUrl?: string | null) => {
  if (!eventUrl) {
    return null;
  }

  const splitUrl = eventUrl.split("?");

  if (splitUrl.length < 2) {
    return null;
  }

  const urlParams = new URLSearchParams(splitUrl[1]);
  const eventId = urlParams.get("prodsku");

  //console.log(`${provider} event Id`, eventId);
  return eventId;
};

const fetchVividSeatsTickets = async (eventUrl?: string | null) => {
  const eventId = getEventIdFromUrl("vivid", eventUrl);
  if (!eventId) return null;
  try {
    const vividSeatsTicketRes = await axios.get(
      `${process.env.REACT_APP_VIVIDSEATS_SCRAPER_URL}?eventId=${eventId}`
    );
    //console.log("fetchVividSeatsTickets()", vividSeatsTicketRes.data.result)
    return vividSeatsTicketRes.data.result;
  } catch (e) {
    console.error("failed to parse vividseats result", e);
    return null;
  }
};

const QuantityDropdown: React.FunctionComponent<{
  onQuantityChange?: (value: React.SetStateAction<number | undefined>) => void;
}> = (props: {
  onQuantityChange?: (value: React.SetStateAction<number | undefined>) => void;
}) => {
  const { onQuantityChange } = props;

  const quantityValues = [
    {
      label: "Any Quantity",
      value: undefined,
    },
    ...QTY_FILTER_VALUES.map((quantity) => ({
      label: `${quantity}${
        quantity === QTY_FILTER_VALUES[QTY_FILTER_VALUES.length - 1] ? "+" : ""
      }`,
      value: quantity,
    })),
  ];

  const handleChange = (
    newValue: SingleValue<{ label: string; value?: number }>
  ) => {
    if (onQuantityChange) {
      onQuantityChange(newValue?.value || undefined);
    }
  };

  return (
    <div>
      <InputLabel id="demo-multiple-chip-label">Quantity:</InputLabel>
      <Select onChange={handleChange} options={quantityValues} />
    </div>
  );
};

const SectionDropdown: React.FunctionComponent<{
  comparisonData: ComparisonData;
  onSectionChange?: (value: React.SetStateAction<string[]>) => void;
}> = (props: {
  comparisonData: ComparisonData;
  onSectionChange?: (value: React.SetStateAction<string[]>) => void;
}) => {
  const { comparisonData, onSectionChange } = props;
  const sectionData = Object.values(comparisonData).map(
    (comparison) => comparison.section
  );
  const uniqueSections = [...new Set(sectionData)];

  const sectionValues = uniqueSections
    .map((section) => ({
      label: `Section: ${section}`,
      value: section,
    }))
    .sort((a, b) => {
      const aNum = Number(a.value);
      const bNum = Number(b.value);
      if (!Number.isNaN(bNum) && !Number.isNaN(Number(aNum))) {
        return aNum - bNum;
      } else if (!Number.isNaN(bNum)) {
        return -1;
      } else if (!Number.isNaN(aNum)) {
        return 1;
      }
      return a.value < b.value ? -1 : 1;
    });

  const handleChange = (
    selectedVal: MultiValue<{
      label: string;
      value: string;
    }>
  ) => {
    if (onSectionChange) {
      onSectionChange(selectedVal.map((val) => val.value));
    }
  };

  return (
    <div>
      <InputLabel id="demo-multiple-chip-label">Section Filter</InputLabel>
      <Select onChange={handleChange} isMulti options={sectionValues} />
    </div>
  );
};

const Event: React.FunctionComponent<{ eventObject: any }> = ({
  eventObject,
}) => {
  //console.log({ eventObject });
  const [selectedSections, setSelectedSections] = useState<string[]>([]);
  const [selectedQuantities, setSelectedQuantities] = useState<number>();

  const theme = useTheme() as Theme;
  const existProviders = new Map<string, boolean>();
  const providerScore = new Map<string, number>();

  const events = eventObject.events.filter((event: any) => {
    if (!event) return false;
    const eventProviderName = event.eventProvider.name.toLowerCase();
    if (existProviders.has(eventProviderName)) {
      return false;
    } else {
      existProviders.set(eventProviderName, true);
      return true;
    }
  }) as [];

  

  (events as Record<string, any>[])
    .filter((event) => event.url)
    .map((e) => {
      e.minPriceWithMarkup = e.minPrice
        ? e.minPrice +
          calculateMarkupFee(e.minPrice, e.eventProvider.markupFees || [])
        : null;
      return e;
    })
    .forEach((event: any, index: number) => {
      const s = Math.max(3, 5 - index);
      providerScore.set(event.eventProvider.name, s);
    });
  const allProviders = window.app_preload.eventProviders as [];

  const venueName = (events as Record<string, any>[]).find(
    (e: Record<string, any>) => e.venue
  )?.venue?.name;

  const tickpickUrl = (events as Record<string, any>[]).find(
    (e: Record<string, any>) => e.eventProvider?.name === "tickpick"
  )?.url;

  const gametimeEventId =  (events as Record<string, any>[]).find(
    (e: Record<string, any>) => e.eventProvider?.name === "gametime"
  )?.id;

  const vividSeatsProvider = eventObject?.events?.find(
    (event: any) => event?.eventProvider?.name === "vividseats"
  );
  const vividSeatsUrl = vividSeatsProvider?.url;
  const parseSection = (sectionId: string) => {
    try {
      // extract just the digits
      const sectionNumbers = sectionId.replace(/\D/g, "");
      if (sectionNumbers.trim().length > 0) {
        // if section id has numbers, only compare numbers (112 vs Upper level 112)
        return sectionNumbers;
      }

      return sectionId
        .toLowerCase()
        .replace("section", "")
        .replace("lower deck", "") // handle this case individually, as it's rare
        .replace("upper deck", "") // handle this case individually, as it's rare
        .trim();
    } catch (e) {
      console.error("error parsing section: ", e);
      return sectionId;
    }
  };

  const parsePrice = (price: string) =>
    Number(price.replace("$", "").replace(",", "").trim());

  const stubhubEventUrl = eventObject.events.find(
    (event: any) => event.eventProvider.name === "stubhub"
  ).url;
  //console.log({ stubhubEventUrl });

  const { data: seatMapSrc = {}, isLoading: seatMapLoading } = useQuery({
    queryKey: [
      "staticSeatMap",
      {
        eventId: eventObject.id,
      },
    ],
    queryFn: async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_STUBHUB_MAP_SCRAPER_URL}/?eventId=${eventObject.id}`
      );

      return res.data.result;
    },
  });

  const { data: stubhubData = {}, isLoading: stubhubIsLoading } =
    useQuery<TicketMap>({
      queryKey: [
        "fetchStubhubEvent",
        {
          eventId: eventObject.id,
        },
      ],
      queryFn: async () => {
        //console.log("[Events] events", {events})
        const ticketData = await fetchStubhubTickets(eventObject.id);
        return ticketData.reduce((obj: TicketMap, ticket: any) => {
          const sectionKey = parseSection(ticket.Section);
          const rowKey = ticket.Row;
          const key = `${sectionKey}-${rowKey}`;
          const currentTicket = {
            section: sectionKey,
            row: rowKey,
            url: stubhubEventUrl + `?listingId=${ticket.Id}`,
            price: parsePrice(ticket.Price),
            priceWithFees: parsePrice(ticket.PriceWithFees || ticket.Price),
            minQuantity: Number(ticket.QuantityRange.split("-")[0]),
            maxQuantity:
              ticket.QuantityRange.split("-").length > 1
                ? Number(ticket.QuantityRange.split("-")[1])
                : Number(ticket.QuantityRange),
          };

          return {
            ...obj,
            [key]:
              obj[key] && obj[key].price < currentTicket.price
                ? obj[key]
                : currentTicket,
          };
        }, {});
      },
      refetchOnWindowFocus: false,
    });

    // Use Query
    const { data: gametimeData = {}, isLoading: gametimeLoading } =
      useQuery<TicketMap>({
        queryKey: [
          "fetchGametimeEvent",
          {
            eventId: gametimeEventId,
          },
        ],
        queryFn: async () => {
          if (!gametimeEventId) return {};
    
          const ticketData = await fetchGameTimeTickets(gametimeEventId);

          
          //console.log("---->Gametime TicketData");
          //console.log({ ticketData });
    
          return ticketData.reduce((obj: TicketMap, ticket: Ticket) => {
            const sectionKey = ticket.section;
            const rowKey = ticket.row;
            const key = `${sectionKey}-${rowKey}`;
            const currentTicket = ticket;
    
            return {
              ...obj,
              [key]:
                obj[key] && obj[key].price < currentTicket.price
                  ? obj[key]
                  : currentTicket,
            };
          }, {});
        },
        refetchOnWindowFocus: false,
      });

      
  const { data: tickpickData = {}, isLoading: tickpickLoading } =
    useQuery<TicketMap>({
      queryKey: [
        "fetchTickpickEvent",
        {
          eventUrl: tickpickUrl,
        },
      ],
      queryFn: async () => {
        const eventId = getEventIdFromUrl("tickpick", tickpickUrl);
        const ticketData = await fetchTickpickTickets(tickpickUrl);
        //console.log({ ticketData });
        return ticketData.reduce((obj: TicketMap, ticket: any) => {
          const sectionKey = parseSection(ticket.section_id);
          const rowKey = ticket.row;
          const key = `${sectionKey}-${rowKey}`;
          const currentTicket = {
            section: sectionKey,
            row: rowKey,
            url: `https://www.tickpick.com/checkout?e=${eventId}&listingId=${ticket.id}&listingType=TP&price=${ticket.price}&s=${ticket.section_id}&r=${ticket.row}&utm_source=impact&utm_medium=Cameron%20Roth&utm_campaign=3912002&utm_content=Online%20Tracking%20Link_656088`,            
            price: ticket.price,
            priceWithFees: ticket.price,
            minQuantity: ticket.quantity,
            maxQuantity: ticket.quantity,
          };

          return {
            ...obj,
            [key]:
              obj[key] && obj[key].price < currentTicket.price
                ? obj[key]
                : currentTicket,
          };
        }, {});
      },
      refetchOnWindowFocus: false,
    });

  // -----  

  const VIVID_SEATS_AVERAGE_FEE = 1.4;
  const { data: vividSeatsData = {}, isLoading: vividSeatsIsLoading } =
    useQuery<TicketMap>({
      queryKey: [
        "fetchVividSeatsEvent",
        {
          eventUrl: vividSeatsUrl,
        },
      ],
      queryFn: async () => {
        const ticketData = await fetchVividSeatsTickets(vividSeatsUrl);
        const eventId = getEventIdFromUrl("vivid", vividSeatsUrl);
        //console.log("VividSeats Tickets Processing")
        //console.log({ticketData, eventId})
        if (!ticketData) return {};

        return ticketData.reduce((obj: TicketMap, ticket: any) => {
          const sectionKey = parseSection(ticket.Section);
          const rowKey = ticket.Row;
          const key = `${sectionKey}-${rowKey}`;
          const currentTicket = {
            section: sectionKey,
            row: rowKey,
            url: `https://vivid-seats.pxf.io/c/3912002/952533/12730?1&u=https://www.vividseats.com/production/${eventId}?showDetails=${ticket.Id}`,            
            price: ticket.Price,
            priceWithFees:
              ticket.PriceWithFees === ticket.Price || !ticket.PriceWithFees
                ? ticket.Price * VIVID_SEATS_AVERAGE_FEE
                : ticket.PriceWithFees,
            id: ticket.Id,
            minQuantity: Number(ticket.QuantityRange.split("-")[0]),
            maxQuantity:
              ticket.QuantityRange.split("-").length > 1
                ? Number(ticket.QuantityRange.split("-")[1])
                : Number(ticket.QuantityRange),
          };

          return {
            ...obj,
            [key]:
              obj[key] && obj[key].price < currentTicket.price
                ? obj[key]
                : currentTicket,
          };
        }, {});
      },
      refetchOnWindowFocus: false,
    });


  // -----  
  const getQuantityRange = (events: Ticket[]) => {
    const eventsWithValues = events.filter(Boolean);
    const minQuantity = Math.min(
      ...eventsWithValues.map((event) => event.minQuantity)
    );
    const maxQuantity = Math.max(
      ...eventsWithValues.map((event) => event.maxQuantity)
    );
    return minQuantity === maxQuantity
      ? `${minQuantity}`
      : `${minQuantity}-${maxQuantity}`;
  };

  const comparisonData: ComparisonData = useMemo(() => {
    const allKeys = Object.keys({
      ...stubhubData,
      ...gametimeData,
      ...tickpickData,
      ...vividSeatsData,
    });
    return allKeys.reduce(
      (obj, key) => ({
        ...obj,
        [key]: {
          section:
            stubhubData[key]?.section ??
            gametimeData[key]?.section ??
            tickpickData[key]?.section ??
            vividSeatsData[key]?.section,
          row:
            stubhubData[key]?.row ??
            gametimeData[key]?.row ??
            tickpickData[key]?.row ??
            vividSeatsData[key]?.row,
          quantity: getQuantityRange([
            stubhubData[key],
            gametimeData[key],
            tickpickData[key],
            vividSeatsData[key],
          ]),
          stubhub: stubhubData[key],
          gametime: gametimeData[key],
          tickpick: tickpickData[key],
          vividSeats: vividSeatsData[key],
        },
      }),
      {}
    );
  }, [stubhubData, gametimeData, tickpickData, vividSeatsData]);

  //console.log("GAMETIME DATA POST PROCESSING")
  //console.log({gametimeData})
  //console.log("{ stubhubData, vividSeatsData, gametimeData, tickpickData, comparisonData }")
  //console.log({ stubhubData, vividSeatsData, gametimeData, tickpickData, comparisonData });

  const allQty = [
    ...new Set(
      Object.values(comparisonData).map((comparison) => comparison.quantity)
    ),
  ];

  //console.log({ allQty });

  const stubhubImg = "/static/images/providers_logos/stubhub_original.png";
  const tickpickImg = "/static/images/providers_logos/tickpick_original.png";
  const gametimeImg = "/static/images/providers_logos/gametime_original.png";
  const vividSeatsImg =
    "/static/images/providers_logos/vividseats_original.png";

  // const ticketMasterEvent: any = events.find((event: any) => {
  //   return event?.eventProvider?.name === "ticketmaster";
  // });

  const filteredComparisonData = filterTickets(
    comparisonData,
    selectedQuantities,
    selectedSections
  );
  return stubhubIsLoading ? 
  (<>
    <div className="flex flex-col items-center mt-[10vh]">
    <h2 className="text-lg text-center max-w-2xl mb-16">
      Just{" "} 
      <span className="relative inline-block">
      seconds
        <div className="bg-[#DC19FF] opacity-20 absolute top-2 w-full h-4 shadow-sm"></div>
      </span>{" "}
      away from your dreams tickets...
      <br/>
      Thank you for your patience!
    </h2>
  </div>
  <SuspenseLoader embedded={true} height="100px" />
  </>)
  
  :(
    <Container
      sx={{
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        height: { xs: "100%", md: "calc(100vh - 100px)" },
        padding: { xs: 0, md: "inherit" },
        //position: "fixed" 
      }}
    >
      
      <PageTitle
        text={eventObject.name}
        variant="large"
        subtitle={formatToLocal(eventObject.date)}
      >
        <Typography variant="h6" color={theme.colors.shadows.primary}>
          {venueName ? venueName + " - " : ""} {eventObject.location}
        </Typography>
      </PageTitle>
      <Stack
        alignItems="center"
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        sx={{
          height: { xs: "calc(100vh - 90px)" },
          gap: { xs: "0.5rem", md: "2rem" },
        }}
        overflow="hidden"
      >
        {seatMapLoading && <CircularProgress />}
        {seatMapSrc && !seatMapLoading && (
          <TransformWrapper>
            <TransformComponent
              wrapperClass="pinch-zoom-wrapper"
              contentClass="pinch-zoom-content"
            >
              <Box
                component="img"
                className="seat-map-img"
                src={seatMapSrc}
              ></Box>
            </TransformComponent>
          </TransformWrapper>
        )}

        <Stack
          sx={{
            // height: "100%",
            height: { xs: "60%", md: "100%" },
            width: { xs: "100%", md: "400px" },
          }}
          overflow="hidden"
        >
          {stubhubIsLoading && vividSeatsIsLoading && gametimeLoading && tickpickLoading ? (
            <CircularProgress />
          ) : (
            <Stack overflow="hidden">
              <Stack direction="row" justifyContent="center" gap={2}>
                <Box sx={{ flex: 1, maxWidth: { xs: "30%", md: "250px" } }}>
                  <QuantityDropdown onQuantityChange={setSelectedQuantities} />
                </Box>
                <Box sx={{ flex: 2, maxWidth: { xs: "60%", md: "250px" } }}>
                  <SectionDropdown
                    comparisonData={comparisonData}
                    onSectionChange={setSelectedSections}
                  />
                </Box>
              </Stack>
              <Box
                display="flex"
                flexDirection="column"
                mt={2}
                pr={1}
                overflow="auto"
              >
                
                {
                Object.keys(filteredComparisonData).map((key) => {
                  
                  // Extract all available prices for the current key
                const prices = [
                  comparisonData[key].stubhub?.priceWithFees,
                  comparisonData[key].gametime?.priceWithFees,
                  comparisonData[key].tickpick?.priceWithFees,
                  comparisonData[key].vividSeats?.priceWithFees,
                ].filter(Boolean); // Filter out undefined prices

                // Find the minimum price
                const lowestPrice = Math.min(...prices);

                return (
                  <Stack
                    key={key}
                    direction="column"
                    sx={{
                      borderRadius: "16px",
                      background: "white",
                      margin: "8px 0",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{
                        padding: "8px 8px 8px",
                        background: "white",
                        borderRadius: "16px",
                      }}
                    >
                      <Typography variant="h4">
                        Section {comparisonData[key].section}, Row{" "}
                        {comparisonData[key].row}
                      </Typography>

                      <Box>Quantity: {comparisonData[key].quantity}</Box>
                    </Box>
                    <Box
                      key={key}
                      display="flex"
                      sx={{
                        flexWrap: "wrap",
                        padding: "8px",
                        gap: "8px",
                      }}
                    >

                      
                      {comparisonData[key].stubhub &&
                        comparisonData[key].stubhub?.price && (
                          <PriceComparisonItem
                            imgSrc={stubhubImg}
                            price={comparisonData[key].stubhub?.priceWithFees}
                            ticketUrl={comparisonData[key].stubhub.url}
                            highlight={comparisonData[key].stubhub.priceWithFees === lowestPrice}
                          />
                        )}
                      {comparisonData[key].gametime && (
                        <PriceComparisonItem
                          imgSrc={gametimeImg}
                          price={comparisonData[key].gametime.priceWithFees}
                          ticketUrl={comparisonData[key].gametime.url}
                          highlight={comparisonData[key].gametime.priceWithFees === lowestPrice}
                        />
                      )}
                      {comparisonData[key].tickpick && (
                        <PriceComparisonItem
                          imgSrc={tickpickImg}
                          price={comparisonData[key].tickpick.priceWithFees}
                          ticketUrl={comparisonData[key].tickpick.url}
                          highlight={comparisonData[key].tickpick.priceWithFees === lowestPrice}
                        />
                      )}
                      {comparisonData[key].vividSeats && (
                        <PriceComparisonItem
                          imgSrc={vividSeatsImg}
                          price={comparisonData[key].vividSeats.priceWithFees}
                          ticketUrl={comparisonData[key].vividSeats.url}
                          highlight={comparisonData[key].vividSeats.priceWithFees === lowestPrice}
                        />
                      )}
                    </Box>
                  </Stack>
                );
              })}
              </Box>
            </Stack>
          )}
        </Stack>
      </Stack>
      {/* <Typography
        color={theme.colors.alpha.black[50]}
        align="center"
        m={theme.spacing(2)}
      >
        {
          "* Price discrepancies may occur if the ticket is already in another person's cart"
        }
        <br />
        <strong>{"Always check site for prices"}</strong>
      </Typography> */}
    </Container>
  );
};

export default Event;
