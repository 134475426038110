import { FC, ReactNode } from "react";

interface ContactUsPageProp {
  children?: ReactNode;
}

const ContactUsInfo = [
  {
    title: "Call Us",
    description: ["713-897-1600"],
    logo: "/static/images/contact/contact-phone.svg",
  },
  {
    title: "Message Us",
    description: ["info@tixplorer.com"],
    logo: "/static/images/contact/contact-email.svg",
  },
  {
    title: "Opening Hours",
    description: ["24/7"],
    logo: "/static/images/contact/contact-hours.svg",
  },
];

const ContactUsPage: FC<ContactUsPageProp> = () => {
  return (
    <div className="flex flex-col items-center max-w-6xl justify-center m-auto">
      <div className="flex flex-col items-center justify-center pt-6 md:pt-10 lg:pt-16">
        <img
          src="/static/images/contact/contact-logo.svg"
          alt=""
          className="h-20 w-auto"
        ></img>
        <h1 className="text-3xl md:text-5xl font-semibold text-black mt-4">
          Contact Us
        </h1>
        <p className="text-lg text-black font-light mt-2 text-center block max-w-2xl p-2">
          Have a question but aren’t sure who to contact? Fill in the form to
          get in touch and a member of our team will reach out to you at the
          earliest.
        </p>
        <img
          src="/static/images/contact/contact-stadium.svg"
          className="h-80 w-auto my-10 md:block hidden"
          alt="contact us map"
        ></img>
      </div>
      <div className="w-full px-4 md:px-32 space-x-16 mt-10 md:mb-20 grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-12">
        <div className="flex flex-col w-full !m-0 items-start p-4 bg-primary-btn rounded-xl">
          <img
            src="/static/images/contact/contact-phone.svg"
            alt="contact phone"
            className="h-12 w-auto"
          ></img>
          <h2 className="text-2xl font-normal text-white mt-6">Call Us</h2>
          <div className="flex flex-col items-start mt-2">
            <a
              className="text-md text-white font-light hover:font-bold"
              href="tel:7138971600"
            >
              713-897-1600
            </a>
          </div>
        </div>
        <div className="flex flex-col w-full !m-0 items-start p-4 bg-primary-btn rounded-xl">
          <img
            src="/static/images/contact/contact-email.svg"
            alt="email"
            className="h-12 w-auto"
          ></img>
          <h2 className="text-2xl font-normal text-white mt-6">Message Us</h2>
          <div className="flex flex-col items-start mt-2">
            <a
              className="text-md text-white font-light hover:font-bold"
              href="mailto:info@tixplorer.com"
            >
              info@tixplorer.com
            </a>
          </div>
        </div>
        <div className="flex flex-col w-full !m-0 items-start p-4 bg-primary-btn rounded-xl">
          <img
            src="/static/images/contact/contact-hours.svg"
            alt="schedule"
            className="h-12 w-auto"
          ></img>
          <h2 className="text-2xl font-normal text-white mt-6">Open Hours</h2>
          <div className="flex flex-col items-start mt-2">
            <p className="text-md text-white font-light">
              Contact us at any time
            </p>
          </div>
        </div>
      </div>
      <div className="w-full md:hidden px-4 flex items-center justify-center">
        <img
          src="/static/images/contact/contact-stadium.svg"
          className="h-80 w-auto my-10 block"
          alt="contact us map"
        ></img>
      </div>
    </div>
  );
};
export default ContactUsPage;
